import { render, staticRenderFns } from "./preClassPay.vue?vue&type=template&id=4f5f835c&scoped=true&"
import script from "./preClassPay.vue?vue&type=script&lang=js&"
export * from "./preClassPay.vue?vue&type=script&lang=js&"
import style0 from "./preClassPay.vue?vue&type=style&index=0&id=4f5f835c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5f835c",
  null
  
)

export default component.exports